.carousel {
    margin-bottom: 26px;
}
.carousel-indicators
{
    margin-bottom: -26px;
}


.carousel-indicators li
{
   background-color: #000;
    background: blue;
    height: 10px;
    width: 10px;
    border-radius: 2px;
}
.carousel-indicators li.active
{
   background-color: #000;
    background: blue;
    height: 10px;
    width: 10px;
    border-radius: 2px;
    transform: rotate(45deg);
}
.leftControl
{
    display :none;
}
.rightControl
{
    display :none;
}
.right 
{
    display :none;
}
.left 
{
    display :none;
}

@media only screen and (max-width: 650px) {
    .carousel-indicators
    {
        margin-bottom: -0px;
    }
  }